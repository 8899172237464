@font-face {
  font-family: "FuturaBT-Book";
  src: local("FuturaBT-Book"), url("./fonts/FuturaBT-Book.otf") format("otf");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-child{
  display: flex;
  flex: 1 1 0px;
}

.user-greeting{
  display:flex;
  margin-top:115px;
  padding: 20px;
  background-color: #257CE1;
  user-select: none;
}

.footer{
  position: absolute;
  margin-bottom: 25px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  background-color: #d3d3d3;
}