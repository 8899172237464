
.fileLabel {
    display: flex;
    margin-left: 50px;
    cursor: pointer;
    user-select: none;
}

.fileLabel:hover {
    color: #257CE1;
}

.no-software {
    display: flex;
    margin-left: 50px;
    user-select: none;
}