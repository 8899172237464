.return-to-sign-in {
  cursor: pointer;
  color: #257ce1;
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
}

.return-to-sign-in:hover {
  text-decoration: underline;
}
