.forgot-password-greeting {
  text-align: center;
  margin-top: 50px;
  padding-top: 50px;
}

.forgot-password-message {
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  /* margin-top: 18px; */
  margin-bottom: 20px;
  font-size: 18px;
}

.forgot-password-email-input {
    padding: 0px 10px !important;
    margin-left: 50px !important;
    margin-right: 50px !important;
    width: calc(100% - 100px) !important;
    height: 40px !important;
    font-size: 18px !important;
    background-color: white !important;
    border: none !important;
    font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
    box-shadow: none;
    /* border-bottom: 2px solid !important; */
    /* text-align: center !important; */
  }

  .send-code-btn {
    margin-top:3px;
    margin-left: 50px;
    width: calc(100% - 100px);
    height: 50px;
    background-color: #257ce1;
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 2px 2px rgba(0,0,0,.25);
  }
  
  .send-code-btn:hover {
    /* background-color: #2c6aed; */
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
  }

  .send-code-error-msg-div {
    display: flex;
    height: 30px;
    width: 100%;
    margin-top: 15px;
    align-items: center;
  }
  
  .send-code-error-msg {
    text-align: center;
    color: #d22b2b;
    vertical-align: middle;
    margin: auto;
    /* visibility: hidden; */
  }
  