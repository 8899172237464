/* Style the tab */
.tab {
    overflow: hidden;
    width: 100%;
  }
  
  /* Style the buttons that are used to open the tab content */
  .tab-button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    width: 50%;
    background-color: #ddd;
    overflow: hidden;
  }
  
  /* Change background color of buttons on hover */
  .tab-button:hover {
    background-color: #b1b1b1;
  }
  
  /* Create an active/current tablink class */
  .tab-button.active{
    pointer-events: none;
    cursor: default;
    background-color: white;
  }