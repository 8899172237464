.create-account-success-div {
    text-align: center;
    display: inline-block;
    width: 100%;
  }
  
  .create-account-success-greeting {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 20px;
      overflow: hidden;
    }

    .create-account-success-msg {
        width: 80%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        /* margin-bottom: 50px; */
    }

    .return-home {
        cursor: pointer;
        color: #257ce1;
        margin-top: 20px;
        text-align: center;
        margin-bottom:50px;
        text-decoration: none;
      }
    
      .return-home:hover {
        text-decoration: underline;
      }
    