.sign-in-greeting {
  text-align: center;
  margin-top: 50px;
  overflow: hidden;
}

.input-header {
  margin-left: 50px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.sign-in-email-input {
  padding: 0px 10px !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
  width: calc(100% - 100px) !important;
  height: 40px !important;
  font-size: 18px !important;
  background-color: white !important;
  border: none !important;
  font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
  box-shadow: none;
  /* border-bottom: 2px solid !important; */
  /* text-align: center !important; */
}

.password {
  padding: 0px 10px !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
  width: calc(100% - 100px) !important;
  height: 50px !important;
  font-size: 18px !important;
  background-color: white !important;
  border: none !important;
  /* border-bottom: 2px solid !important; */
  /* text-align: center !important; */
}

.sign-in-btn {
  margin-top: 5px;
  margin-left: 50px;
  width: calc(100% - 100px);
  height: 50px;
  background-color: #257ce1;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.sign-in-btn:hover {
  /* background-color: #2c6aed; */
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.forgot-password {
  cursor: pointer;
  color: #257ce1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 50px;
}

.forgot-password:hover {
  text-decoration: underline;
}

.tooltip {
  position: relative;
  display: inline-block;
  visibility: hidden;
  background-color: #d3d3d3;
  text-align: left;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  margin-left: -40px;
  margin-bottom: -11px;
  opacity: 1;
  transition: opacity 0.3s;
  font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #d3d3d3 transparent transparent;
}

.tooltip span {
  font-size: 18px;
  margin-left: 5px;
}

.error-msg-div {
  display: flex;
  height: 30px;
  width: 100%;
  margin-top: 10px;
  align-items: center;
}

.error-msg {
  text-align: center;
  color: #d22b2b;
  vertical-align: middle;
  margin: auto;
  /* visibility: hidden; */
}

.loading-gif-img {
  margin: auto;
  height: 100%;
}
