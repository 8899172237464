.auth-main-div {
  background-color: white;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
  width: 30%;
  height: 'auto';
  margin-top: 150px;
  display: flexbox;
  margin-left: auto;
  margin-right: auto;
  font-family: "FuturaBT-Book", Montserrat, sans-serif;
  position: relative;
  margin-bottom: 25px;
  /* overflow: hidden; */
}

/* Style the tab */
.tab {
  overflow: hidden;
  width: 100%;
}

/* Style the buttons that are used to open the tab content */
.tab-button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  width: 50%;
  background-color: #ddd;
  overflow: hidden;
}

/* Change background color of buttons on hover */
.tab-button:hover {
  background-color: #b1b1b1;
}

/* Create an active/current tablink class */
.tab-button.active {
  background-color: white;
}

.auth-creating-account-img-div {
  width: 100%;
  margin: 50px auto 20px auto;
}

.creating-account-img {
  height: 150px;
}

.auth-creating-account-img {
  width: 100%;
  margin-top:20px;
  margin-bottom: 50px;
}

.auth-creating-account-img {
  text-align: center;
  font-weight: bold;
}