.fileContainer {
  background-color: white;
  min-height: 75px;
  margin-top: 150px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  font-family: "FuturaBT-Book", Montserrat, sans-serif;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
}
