.greeting {
  text-align: center;
  margin-top: 50px;
  overflow: hidden;
}

.input-header {
  display: flex !important;
  margin-left: 50px !important;
  margin-top: 20px !important;
  margin-bottom: 0px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
}

.input {
  padding: 0px 10px !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
  width: calc(100% - 100px) !important;
  height: 40px !important;
  font-size: 18px !important;
  background-color: white !important;
  border: none !important;
  font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
  box-shadow: none;
}

.password {
  padding: 0px 10px !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
  width: calc(100% - 100px) !important;
  height: 50px !important;
  font-size: 18px !important;
  background-color: white !important;
  border: none !important;
}

.password:focus {
  outline: none !important;
  border-bottom: 4px solid #257ce1 !important;
}

.password-btn {
  display: inline;
  cursor: pointer;
  height: 30px;
  margin-top: 0;
  margin-bottom: 0;
}

.create-account-btn {
  margin-left: 50px;
  margin-top: 5px;
  margin-bottom: 50px;
  width: calc(100% - 100px);
  height: 50px;
  background-color: #257ce1;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.create-account-btn:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.tooltip {
  position: relative;
  display: inline-block;
  visibility: hidden;
  background-color: #d3d3d3;
  text-align: left;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  margin-left: -40px;
  margin-bottom: -11px;
  opacity: 1;
  transition: opacity 0.3s;
  font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #d3d3d3 transparent transparent;
}

.tooltip label {
  font-size: 18px;
  margin-left: 5px;
}

.create-account-input {
  padding: 0px 10px !important;
  margin-left: 50px !important;
  margin-right: 50px !important;
  margin-top: 40px;
  width: calc(100% - 100px) !important;
  height: 40px !important;
  font-size: 18px !important;
  background-color: white !important;
  border: none !important;
  font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
  box-shadow: none;
}

.create-account-error-msg-div {
  display: flex;
  height: 30px;
  width: 100%;
  margin-top: 10px;
  align-items: center;
}

.create-account-error-msg {
  text-align: center;
  color: #d22b2b;
  vertical-align: middle;
  margin: auto;
  /* visibility: hidden; */
}