.popup-div {
  position: absolute;
  display: flex;
  height: 250px;
  width: 25%;
  margin: auto;
  font-family: "FuturaBT-Book", Montserrat, sans-serif;
  background-color: white;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-self: center;
}

.popup-img-div {
  width: 100%;
  position: absolute;
  top: 20px;
  margin: auto;
}

.popup-img {
  display: flex;
  margin: auto;
  height: 150px;
}

.popup-msg-div {
  display: block;
  width: 100%;
  margin: auto;
  position: absolute;
  bottom: 20px;
}

.popup-msg {
  text-align: center;
  font-weight: bold;
  margin: auto;
}
