@keyframes editAccountFadeIn {
  from {
    max-height: 0px;
  }
  to {
    max-height: 1050px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes fadeOut {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

.edit-account-div {
  /* min-height: 75px; */
  width: 30%;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  font-family: "FuturaBT-Book", Montserrat, sans-serif;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
  background-color: white;
  overflow: hidden;
  animation-name: editAccountFadeIn;
  animation-duration: 2s;
}

.account-info-div {
  background-color: white;
  border-bottom: 1px outset #d3d3d3;
  display: inline-block;
  width: 100%;
  max-height: 475px;
  transition: max-height 0.6s ease-in-out;
  overflow: hidden;
}

.account-info-header-div {
  width: 100%;
  display: flex;
  padding-left: 60px;
  padding-right: 48px;
  padding-top: 0px;
}

.account-info-header {
  margin: 0px;
  /* padding-left: 60px; */
  padding-top: 50px;
  padding-bottom: 25px;
  /* width: 85%; */
}

.info-change-success {
  margin: 0px;
  padding-top: 55px;
  padding-bottom: 25px;
  padding-left: 15px;
  color: #009e60;
}

.change-info-error-msg-div {
  /* display: flex; */
  /* position: absolute; */
  height: 30px;
  width: 100%;
  margin-top: 10px;
  align-items: center;
}

.change-info-error-msg {
  text-align: center;
  color: #d22b2b;
  vertical-align: middle;
  margin: auto;
  /* visibility: hidden; */
}

.save-account-info-btn {
  margin-left: 60px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: calc(100% - 120px);
  height: 50px;
  background-color: #257ce1;
  color: white;
  border: none;
  /* cursor: pointer; */
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
  transition: opacity 0.3s linear;
}

.save-account-info-btn:hover {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.save-account-info-btn:focus {
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
  outline: none;
}

.disabled {
  opacity: 0%;
  pointer-events: none;
  /* position: absolute; */
}

.cancel-changes {
  cursor: pointer;
  color: #257ce1;
  /* margin-left: auto;
  margin-right: auto; */
  /* margin-top: 10px; */
  text-align: center;
  margin-bottom: 25px;
  transition: opacity 0.3s linear;
}

.cancel-changes:hover {
  text-decoration: underline;
}

.cancel-changes:focus {
  text-decoration: underline;
}

.email-div {
  background-color: white;
  border-bottom: 1px outset #d3d3d3;
  display: inline-block;
  width: 100%;
  max-height: 475px;
  transition: max-height 0.6s ease-in-out;
  overflow: hidden;
}

.email-header-div {
  width: 100%;
  display: flex;
  padding-left: 60px;
  padding-right: 48px;
  padding-top: 0px;
}

.email-header {
  margin: 0px;
  /* padding-left: 60px; */
  padding-top: 25px;
  padding-bottom: 25px;
  /* width: 85%; */
}

.email-verified-div {
  padding-left: 80px;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.email-verified {
  color: black;
  font: 18px;
}

.email-verified-check {
  margin-left: 10px;
  margin-bottom: -3px;
}

.verify-email-question {
  color: black;
  margin-left: 15px;
  font-size: 16px;
  cursor: pointer;
}

.verify-email-question:hover {
  text-decoration: underline;
  /* font-weight: bold; */
  color: #257ce1;
}

.verify-email-question:focus {
  text-decoration: underline;
  font-weight: bold;
  color: #257ce1;
  outline: none;
}

.change-email-error-msg-div {
  /* display: flex; */
  height: 20px;
  width: 100%;
  margin-top: -5px;
  align-items: center;
}

.change-email-error-msg {
  text-align: center;
  color: #d22b2b;
  vertical-align: middle;
  margin: auto;
  /* visibility: hidden; */
}

.change-password-div {
  background-color: white;
  border-bottom: 1px outset #d3d3d3;
  display: inline-block;
  width: 100%;
  max-height: 86px;
  transition: max-height 0.6s ease-in-out;
  overflow: hidden;
}

.change-password-header-div {
  width: 100%;
  display: flex;
  padding-left: 60px;
  padding-right: 48px;
  padding-top: 0px;
}

.change-password-header {
  margin: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  /* padding-bottom: 25px; */
}

.password-change-success {
  margin: 0px;
  padding-top: 30px;
  padding-bottom: 25px;
  padding-left: 15px;
  color: #009e60;
}

.change-password-error-msg-div {
  display: flex;
  height: 30px;
  width: 100%;
  margin-top: 10px;
  align-items: center;
}

.change-password-error-msg {
  text-align: center;
  color: #d22b2b;
  vertical-align: middle;
  margin: auto;
  /* visibility: hidden; */
}

.return-to-downloads-div {
  margin-top: 0px;
}
