.password-reset-success-div {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.password-reset-success-greeting {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  