.password-tooltip {
  display: inline-block;
  visibility: visible;
  width: 275px;
  background-color: #d3d3d3;
  text-align: left;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  margin-left: -40px;
  margin-top: 30px;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
}

.password-tooltip::after {
  content: "";
  position: absolute;
  top: 13%;
  right: 100%;
  margin-top: 0px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #d3d3d3 transparent transparent;
}

.password-label {
  color: black;
  display: inline-flex;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: -10px;
  font-size: 18px;
}

.password-list {
  color: black;
  list-style: none inside none;
}

.password-list-item {
  display: inline-flex;
  margin-left: -20px;
}

.password-list-item label {
  font-size: 18px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.password-list-icon {
  display: inline-flex;
  margin-top: 5px;
}

