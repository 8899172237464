body {
  background-color: #d3d3d3;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.wait, body.wait * {
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "FuturaBT-Book";
  src: local("FuturaBT-Book"), url("./fonts/FuturaBT-Book.otf") format("otf");
}

::selection {
  background: #257ce1;
  color: white;
}