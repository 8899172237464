  .reset-pw-loading-img-div {
    width: 100%;
    margin: 50px auto 20px auto;
  }
  
  .reset-pw-loading-img {
    /* display: flex; */
    /* margin: auto 20px; */
    height: 150px;
  }
  
  .reset-pw-loading-msg-div {
    /* display: block; */
    width: 100%;
    margin-top:20px;
    /* position: absolute; */
    margin-bottom: 0px;
  }
  
  .reset-pw-loading-msg {
    text-align: center;
    font-weight: bold;
    /* margin: auto; */
  }
  