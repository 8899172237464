.navBarDiv {
  width: 100%;
  overflow: hidden;
  height: 115;
  position: fixed;
  top: 0px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  /* transition: 0.1s; */
  z-index: 9999;
}

.innerDiv {
  /* height: 115; */
  overflow: visible;
}

.row {
  max-width: 1170px;
  /* height: 115px; */
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.logoDiv {
  margin-top: 16px;
  margin-bottom: 22px;
  margin-left: 0px;
  margin-right: 0px;
  display: block;
  float: left;
  /* transition: 0.1s; */
}

.logoImg {
  max-height: 77;
  height: 77;
  width: auto;
  border-style: none;
  vertical-align: top;
  max-width: 100%;
  aspect-ratio: auto 292 / 77;
  overflow-clip-margin: content-box;
  overflow: clip;
  /* transition: 0.1s; */
}

.nav-menu {
  background-color: white;
  /* float: right; */
  margin-left: 25px;
  position: absolute;
  left: 683px;
  height: 100%;
  z-index: 200;
  overflow: hidden;
  display: block;
  /* width:71%; */
}

.nav-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  height: 100%;
}

.nav-menu li {
  display: list-item;
  float: left;
  cursor: pointer;
  margin: 0;
  padding: 0;
  padding-right: 55px;
  position: relative;
  height: 100%;
}

.nav-menu a {
  height: 100%;
  color: #666666;
  box-sizing: border-box;
  border-top-width: 6px;
  border-top-style: solid;
  justify-content: center;
  border-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  font-family: "FuturaBT-Book", Montserrat, sans-serif;
  font-weight: 300;
}

.nav-menu span {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  font-family: "FuturaBT-Book", Montserrat, sans-serif;
  color: inherit;
  font-weight: 300;
  font-size: 17px;
  font-style: normal;
  cursor: pointer;
  align-items: center;
}

.nav-menu a:hover {
  color: #257ce1;
  border-color: #257ce1;
}

.nav-menu .selected {
  color: #257ce1;
  border-color: #257ce1;
}

.account-menu-btn-div {
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 115px;
  top: 0px;
  right: 0px;
  position: absolute;
  /* cursor: pointer; */
  border: none;
  /* transition: background-color 0.6s linear; */
  /* cursor: pointer; */
}


/* Style the accordion chevron icon */
.chevron-icon {
  display: inline-flex;
  /* display: flex; */
  /* float: right; */
  margin-right: 20px;
  margin-left: 20px;
  margin-top: auto;
  margin-bottom:auto;
  /* margin-left: auto; */
  transition: transform 0.6s ease;
  transform: rotate(90deg);
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(270deg);
}


.account-menu-div {
  min-width: 215px;
  position: fixed;
  background-color: white;
  height:auto;
  right: 0;
  top:115px;
  /* z-index: 9000; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: max-height 0.6s ease-in-out;
  /* max-height: 0px; */
  overflow: hidden;
  user-select: none;
}

.account-menu-dropdown-item {
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
  font-weight: normal;
  font-family: "FuturaBT-Book";
  width:100%;
  height: 50%;
  padding-left: 20px;
  margin:auto;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: 0.2s linear;
  background-color: white;
  border: none;
  box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.25);
}

.account-menu-dropdown-item:hover {
  background-color: #257ce1;
  color: white;
  font-weight: bold;
}