.reset-code-greeting {
  text-align: center;
  margin-top: 50px;
  padding-top: 50px;
}

.reset-code-message {
  text-align: center;
  margin-left: 60px;
  margin-right: 60px;
  /* margin-top: 18px; */
  margin-bottom: 20px;
  font-size: 18px;
}

.reset-code-input {
    margin-bottom: 15px;
    padding: 0px 10px !important;
    margin-left: 50px !important;
    margin-right: 50px !important;
    width: calc(100% - 100px) !important;
    height: 40px !important;
    font-size: 18px !important;
    background-color: white !important;
    border: none !important;
    font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
    box-shadow: none;
}

.reset-password-btn {
  margin-top: 3px;
  margin-left: 50px;
  width: calc(100% - 100px);
  height: 50px;
  background-color: #257ce1;
  color: white;
  border: none;
  cursor: pointer;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.reset-password-btn:hover {
  /* background-color: #2c6aed; */
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.verify-code-error-msg-div {
  display: flex;
  height: 30px;
  width: 100%;
  margin-top: 5px;
  align-items: center;
}

.verify-code-error-msg {
  text-align: center;
  color: #d22b2b;
  vertical-align: middle;
  margin: auto;
  /* visibility: hidden; */
}

.password-label {
    padding: "0px 25px";
    margin-left: "10px";
    margin-right: "50px";
    width: "calc(100% - 50px)";
    height: "40px";
    font-size: "20px";
    background-color: "white";
    font-family: "FuturaBT-Book";
    box-shadow: "none";
    font-weight: "bold",
}

.reset-password-tooltip {
    position: relative;
    display: inline-block;
    /* visibility: hidden; */
    background-color: #d3d3d3;
    text-align: left;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    margin-left: -40px;
    margin-top: 30px;
    transition: opacity 0.3s;
    opacity: 0;
    font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
  }
  
  .reset-password-tooltip::after {
    content: "";
    position: absolute;
    top: 30%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #d3d3d3 transparent transparent;
  }
  
  .reset-password-tooltip label {
    font-size: 18px;
    margin-left: 5px;
  }

  .code-tooltip {
    position: relative;
    display: inline-block;
    /* visibility: hidden; */
    background-color: #d3d3d3;
    text-align: left;
    width: 150px;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    margin-left: -40px;
    margin-top: -15px;
    transition: opacity 0.3s;
    opacity: 0;
    font-family: "FuturaBT-Book", Montserrat, sans-serif !important;
  }
  
  .code-tooltip::after {
    content: "";
    position: absolute;
    top: 70%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #d3d3d3 transparent transparent;
  }
  
  .code-tooltip label {
    font-size: 18px;
    margin-left: 5px;
  }